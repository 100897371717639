import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getSiteData } from 'lib/wp/site';
import { getPagePost } from 'lib/wp/cpt/page';
import { Loading } from 'components/Animation/Loading';
import PostPageComponent from 'components/Pages/Post';
import { setContentGrouping } from 'utils/seo';
import { getLocale } from 'lib/i18n/utils';

export default function SinglePage({ hero = {}, data = {} }) {
	const router = useRouter();
	const locale = getLocale(router.locale || router.defaultLocale);
	const isHomepage =
		router.asPath === '/' ||
		router.asPath.startsWith('/?') ||
		data?.post?.slug?.toLowerCase() === 'homepage' ||
		['/home/', '/home'].includes(router.asPath);

	if (router.isFallback) {
		return <Loading />;
	}

	setContentGrouping(data.post, locale);

	return <PostPageComponent hero={hero} isHomepage={isHomepage} shouldHideMeta {...data} />;
}

export async function getServerSideProps({ locale, params, preview, previewData }) {
	const args = params.args ?? [];
	const slug = !args.length ? 'homepage' : args.at(-1);
	const isPreview = args.at(0) === '_preview';

	try {
		const { menus, epConfig, translation } = await getSiteData({ locale });
		const { meta, post, hero } = await getPagePost({
			slug: isPreview ? previewData.id : slug,
			isPreviewMode: isPreview,
			locale,
			token: process.env.STARBUCKS_PREVIEW_TOKEN,
		});

		if (
			!isPreview &&
			post.redirect_to !== null &&
			typeof post.redirect_to?.redirect_to !== 'undefined'
		) {
			return {
				redirect: {
					destination: post.redirect_to.redirect_to,
					statusCode: post.redirect_to.status_code,
				},
			};
		}

		return post.id
			? {
					props: {
						meta,
						menus,
						hero,
						data: { post },
						translation,
						epConfig,
					},
			  }
			: { notFound: true };
	} catch (error) {
		console.log(error);
		return { notFound: true };
	}
}

SinglePage.propTypes = {
	hero: PropTypes.object,
	data: PropTypes.object,
};
